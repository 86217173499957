body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.normal {
  -webkit-animation-duration: 1s;
          animation-duration: 1s
}

.forwards {
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.backwards {
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
}
.circle {
    position: relative;
}

.circle__progress {
    fill: none;
    stroke-width: 3;
    stroke-opacity: 0.3;
    stroke-linecap: round;
}

.percent {
    width: 100%;
    top: 50%;
    left: 50%;
    position: absolute;
    font-weight: bold;
    text-align: center;
    line-height: 28px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.percent__int {
    font-size: 28px;
}

.percent__dec {
    font-size: 12px;
}

.label {
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 15px;
}

.circle__progress--fill {
    stroke-opacity: 1;
    
}

.circle__svg {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}
