.circle {
    position: relative;
}

.circle__progress {
    fill: none;
    stroke-width: 3;
    stroke-opacity: 0.3;
    stroke-linecap: round;
}

.percent {
    width: 100%;
    top: 50%;
    left: 50%;
    position: absolute;
    font-weight: bold;
    text-align: center;
    line-height: 28px;
    transform: translate(-50%, -50%);
}

.percent__int {
    font-size: 28px;
}

.percent__dec {
    font-size: 12px;
}

.label {
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 15px;
}

.circle__progress--fill {
    stroke-opacity: 1;
    
}

.circle__svg {
    transform: rotate(-90deg);
}